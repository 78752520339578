import { defineStore } from 'pinia';

export const createStore = defineStore('main', {
  state: () => ({
    messageEvent: null,
  }),
  actions: {
    setMessageEvent(event) {
      this.messageEvent = event;
    },
    clearMessageEvent() {
      this.messageEvent = null;
    },
  },
});