<template>
  <div :class="`${theme}`">
    <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div class="relative py-3 sm:max-w-xl sm:mx-auto">
        <div class="absolute inset-0 bg-gradient shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div class="max-w-md mx-auto">
            <div class="text-center mb-6">
              <img :src="logo" class="mx-auto"/>
            </div>
            <div class="divide-y divide-gray-200">
              <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <form class="space-y-3" @submit.prevent="submitForm">
                <div class="relative">
                  <input autocomplete="off" v-model="username" id="username" name="username" type="text" required="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Username" />
                  <label for="username" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"></label>
                </div>
                <div class="relative">
                  <input autocomplete="off" v-model="password" id="password" name="password" type="password" required="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Password" />
                  <label for="password" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"></label>
                </div>
                <div class="relative">
                  <button type="submit" @click="submitForm" :disabled="!isValidForm" class="flex w-full justify-center rounded-md btn px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Sign in</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .theme-default .bg-gradient {
    background: linear-gradient(to right, #f2b3b3, #ef4444);
  }
  .theme-default .btn {
    background-color: #ef4444;
    border-color: #dc2626;
  }
  .theme-default .btn:hover {
    background-color: #dc2626;
  }
  .theme-default .text-input {
    border-color: #ef4444;
  }

  .theme-grampians .bg-gradient {
    background: linear-gradient(to right, rgba(214, 152, 31, 0.1), rgba(214, 152, 31, 1));
  }
  .theme-grampians .btn {
    background-color: rgba(214, 152, 31, 1);
    border-color: rgba(214, 152, 31, 0.8);
  }
  .theme-grampians .btn:hover {
    background-color: rgba(214, 152, 31, 0.8);
  }
  .theme-grampians .text-input {
    border-color: rgba(214, 152, 31, 1);
  }

  .theme-yarra .bg-gradient {
    background: linear-gradient(to right, rgba(89, 44, 53, 0.1), rgba(89, 44, 53, 1));
  }
  .theme-yarra .btn {
    background-color: rgba(168, 77, 95, 1);
    border-color: rgba(168, 77, 95, 0.8);
  }
  .theme-yarra .btn:hover {
    background-color: rgba(168, 77, 95, 0.8);
  }
  .theme-yarra .text-input {
    border-color: rgba(168, 77, 95, 1);
  }
</style>

<script>
import defaultLogo from '@/assets/logo.svg';
import grampiansLogo from '@/assets/grampians.png';
import yarraLogo from '@/assets/yarra.svg';
import { createStore } from '../store/main';

export default {
  mounted() {
      const store = createStore();
      this.handleMessage({ data: store.messageEvent });

  },

  data() {
    return { username: '', password: '', theme: 'theme-default', redirect: '/' };
  },

  computed: {
    isValidForm() {
      return this.username.trim() !== '' && this.password.trim() !== '';
    },

    logo() {
      if (this.theme === 'theme-grampians') {
        return grampiansLogo;
      } else if (this.theme === 'theme-yarra') {
        return yarraLogo;
      } else {
        return defaultLogo;
      }
    },
  },

  methods: {
    handleMessage(event) {
      if (event.data.theme === 'login-theme-default') {
        this.theme = 'theme-default';
      } else if (event.data.theme === 'login-theme-grampians') {
        this.theme = 'theme-grampians';
      } else if (event.data.theme === 'login-theme-yarra') {
        this.theme = 'theme-yarra';
      }

      if (event.data.redirect) {
        this.redirect = event.data.redirect;
      }

      return;
    },

    async submitForm() {
      const credentials = btoa(this.username + ':' + this.password);
      const response = await fetch('/access/auth', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password
        })
      });

      if (response.ok) {
          window.location.href = this.redirect;
      }
    }
  }
};
</script>