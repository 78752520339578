<template>
    <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div class="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <div class="flex flex-col items-center justify-center text-center space-y-2">
                    <img src="@/assets/logo.svg" alt="ROAM" class="mx-auto"/>
                    <h2 class="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">Get a login code emailed to you</h2>
                </div>

                <div class="pt-10">
                    <form class="space-y-6" @submit.prevent="sendCode">
                        <div>
                            <label for="email" class="block text-sm font-medium leading-6 text-gray-900"></label>
                            <div class="mt-2">
                                <input id="email" v-model="email" name="email" type="email" autocomplete="email" required="" placeholder="example@roamhq.io" class="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-red-700" />
                            </div>
                        </div>

                        <div class="flex flex-col space-y-5">
                            <div>
                                <button type="submit" :disabled="!isValidForm" class="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-blue-700 border-none text-white text-sm shadow-sm">Send me a code</button>
                          </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    expose: ['sendCode'],

    computed: {
        isValidForm() {
            return this.email.trim() !== '';
        }
    },

    methods: {
        async sendCode() {
            fetch("/access/send-code", {
                method: "POST",
                body: JSON.stringify({ email: this.email }),
                headers: { "Content-Type": "application/json", 'accept': 'application/json' }
            });

            this.$emit('code-sent', this.email);
        },
    },

    data() {
        return { email: '' };
    },
};
</script>