<template>
    <OtpMenuEmail v-show="!step2" ref="otpMenuEmail" @code-sent="handleCodeSent" />
    <OtpMenuCode v-if="step2" :email="email" @send-code="handleSendCode" />
</template>

<script>
import OtpMenuCode from './OtpMenuCode.vue';
import OtpMenuEmail from './OtpMenuEmail.vue';

export default {
    components: {
        OtpMenuCode,
        OtpMenuEmail
    },

    data() {
        return {
            step2: false,
            email: ''
        };
    },

    methods: {
        handleCodeSent(email) {
            this.email = email;
            this.step2 = true;
        },

        handleSendCode() {
            this.$refs.otpMenuEmail.sendCode();
        }
    }
};
</script>
