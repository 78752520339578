document.title = "Roam";

import { createApp } from 'vue'
import { createPinia } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import './assets/styles/index.css'
import OverlayMenu from './components/OverlayMenu.vue'
import LoginMenu from './components/LoginMenu.vue'
import OtpMenu from './components/OtpMenu.vue'

const routes = [
  { path: '/', component: OverlayMenu },
  { path: '/login', component: LoginMenu },
  { path: '/admin/access', component: OtpMenu },
  { path: '/:catchAll(.*)', component: OverlayMenu }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

const pinia = createPinia();
const app = createApp(App)
app.use(pinia);
app.use(router)
app.mount('#app')