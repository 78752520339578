<template>
    <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">

    <div class="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <div class="flex flex-col items-center justify-center text-center space-y-2">
          <div class="font-semibold text-3xl">
            <img src="@/assets/logo.svg" alt="ROAM" class="mx-auto"/>
          </div>
          <div class="flex flex-row text-sm font-medium text-gray-400">
            <p>We have sent a code to your email {{ email }}</p>
          </div>
        </div>

        <div class="pt-10">
          <form action="" @submit.prevent="sendCode">
            <div class="flex flex-col space-y-16">
              <div class="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                <div class="w-12 h-12" v-for="n in 6" :key="n">
                  <input
                    class="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-red-700"
                    type="text"
                    v-model="digit[n - 1]"
                    @input="onInput(n)"
                    @paste="onPaste($event)"
                    @keydown="onKeyDown($event, n)"
                    ref="inputRefs"
                  />
                </div>
              </div>

              <div v-if="codeSent" class="rounded-md bg-green-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">Code sent</p>
                  </div>
                  <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                      <button type="button" class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                        <span class="sr-only">Dismiss</span>
                        <XMarkIcon class="h-5 w-5" aria-hidden="true" @click="reset"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!isValid" class="rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-red-800">Incorrect code</p>
                  </div>
                  <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                      <button type="button" class="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">
                        <span class="sr-only">Dismiss</span>
                        <XMarkIcon class="h-5 w-5" aria-hidden="true" @click="reset"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col space-y-5">
                <div>
                  <button @click="verifyCode" :disabled="!isValidForm" class="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-blue-700 border-none text-white text-sm shadow-sm">
                    Verify
                  </button>
                </div>

                <div class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                  <p>Didn't receive code?</p> <a @click.prevent="triggerSendCode" class="flex flex-row items-center text-blue-600" href="#" rel="noopener noreferrer">Resend</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { XCircleIcon } from '@heroicons/vue/20/solid'
import { CheckCircleIcon, XMarkIcon } from '@heroicons/vue/20/solid'

export default {
    components: {
        XCircleIcon,
        CheckCircleIcon,
        XMarkIcon
    },

    props: {
        email: String,
    },

    computed: {
        isValidForm() {
            return this.code.length == 6;
        },

        isValidCode() {
            return this.isValid == true;
        }
    },

    methods: {
        onInput(index) {
            const value = this.digit[index - 1];

            if (value.length > 1) {
                this.digit[index - 1] = value.slice(0, 1);
            } else if (value.length === 1 && index < 6) {
                this.$refs.inputRefs[index].focus();
            }

            this.code = this.digit.join('');
        },

        onPaste(event) {
            const pasteData = event.clipboardData.getData('text');

            if (pasteData.length === 6) {
                this.digit = pasteData.split('');
                this.digit.forEach((digit, i) => {
                    this.$refs.inputRefs[i].value = digit;
                });
                this.code = this.digit.join('');
                this.$refs.inputRefs[5].focus();

                event.preventDefault();
            }
        },

        onKeyDown(event, index) {
            if (event.key === 'Backspace') {
                event.preventDefault();

                if (this.digit[index - 1] === '') {
                    if (index > 1) {
                        this.$refs.inputRefs[index - 2].focus();
                    }
                } else {
                    this.digit[index - 1] = '';
                }

                this.code = this.digit.join('');
            }
        },

        triggerSendCode() {
            this.$emit('send-code');

            this.codeSent = true;
            this.isValid = true;
        },

        async verifyCode() {
            const response = await fetch("/access/verify-code", {
                method: "POST",
                body: JSON.stringify({ code: this.code }),
                headers: { "Content-Type": "application/json", 'accept': 'application/json' }
            });

            if (response.ok) {
                window.location.href = '/admin';
            } else {
                this.codeSent = false;
                this.isValid = false;
            }
        },

        reset () {
            this.codeSent = false;
            this.isValid = true;
        },
    },

    watch: {
        digit: {
            handler() {
                this.code = this.digit.join('');
            },
            deep: true,
        }
    },

    data() {
        return {
            digit: Array(6).fill(''),
            code: '',
            isValid: true,
            codeSent: false,
        };
    },

    mounted() {
        this.$refs.inputRefs[0].focus();
    },
};
</script>

